import React from "react";

const ParkingIcon = ({ width, height }) => {
  return (
    <svg
      width={width ?? "66"}
      height={height ?? "55"}
      viewBox="0 0 66 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.29268 53.2175V0.797682H17.1462V53.2175H1.29268Z"
        stroke="#C9D1E4"
        strokeWidth="1.58535"
        strokeMiterlimit="10"
      />
      <path
        d="M25.0739 53.2175V0.797682H40.9275V53.2175H25.0739Z"
        stroke="#C9D1E4"
        strokeWidth="1.58535"
        strokeMiterlimit="10"
      />
      <path
        d="M48.8532 53.2175V0.797682H64.7068V53.2175H48.8532Z"
        stroke="#017BF1"
        strokeWidth="1.58535"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default ParkingIcon;
