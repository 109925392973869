import React from "react";

const PlannerIcon = ({ width, height }) => {
  return (
    <svg
      width={width ?? "65"}
      height={height ?? "56"}
      viewBox="0 0 65 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.1888 54.5486H23.5635V44.7767H64.1888V54.5486Z"
        stroke="#C9D1E4"
        strokeWidth="1.62501"
        strokeMiterlimit="10"
      />
      <path
        d="M17.0625 20.3549L17.0625 54.5486H0.812382L0.812382 20.3549H17.0625Z"
        stroke="#C9D1E4"
        strokeWidth="1.62501"
        strokeMiterlimit="10"
      />
      <path
        d="M64.1875 0.817482V38.2673L23.5622 38.2673L23.5622 0.817482L64.1875 0.817482Z"
        stroke="#017BF1"
        strokeWidth="1.62501"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default PlannerIcon;
