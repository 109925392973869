import React from "react";
import car from "../../images/car.png";
import { Container1440Responsive } from "../common/global.styled";

const TableDesktop = () => {
  return (
    <div className="bg-blues-dark-blue text-neutral-white custom-table">
      <Container1440Responsive className="py-[100px]">
        <table
          className="w-full text-center typo-table"
          cellPadding="0"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th className="w-[110px]"></th>
              <th className="w-5 text-right"></th>
              <th className="w-9 text-right"></th>
              <th colSpan="4" className=" border-none p-0">
                <div className="flex flex-row w-full h-full">
                  <div
                    className="w-1/3 flex items-end 
                  "
                  >
                    <p className="text-blues-light-blue font-normal pt-[45px] pb-[22px]">
                      SOUTH
                    </p>
                  </div>
                  <div className="w-1/3 pt-[35px] pb-8 border-x-[1px] border-t-[1px]">
                    PLAZA 273
                  </div>
                  <div className="w-1/3 flex items-end justify-end text-right border-0 ">
                    <p className=" text-blues-light-blue  font-normal pt-[45px] pb-[22px]">
                      NORTH
                    </p>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">16</td>
              <td colSpan="4">Mechanical</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">15</td>
              <td colSpan="4">Direct TV</td>
            </tr>
            <tr>
              <td></td>
              <td>
              <div className="ball"></div>
              </td>
              <td className=" text-right">14</td>
              <td colSpan="3">Mass Mutual</td>
              <td colSpan="1" className=" bg-blues-light-blue">
                <b>AVAILABLE</b> 2.263 RSF
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className="ball"></div>
              </td>
              <td className=" text-right">13</td>
              <td colSpan="4" className=" bg-blues-light-blue">
                <b>AVAILABLE</b> 15.521 RSF
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">12</td>
              <td colSpan="2">Home Land Security</td>
              <td colSpan="2">Direct TV</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">11</td>
              <td colSpan="4">Home Land Security</td>
            </tr>
            <tr>
              <td></td>
              <td>
              <div className="ball"></div>
              </td>
              <td className=" text-right">10</td>
              <td colSpan="4" className=" bg-blues-light-blue">
                <b>AVAILABLE</b> 15.541 RSF
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className="ball"></div>
              </td>
              <td className=" text-right">9</td>
              <td className="bg-blues-light-blue">
                <b>AVAILABLE</b> 2.647 RSF
              </td>
              <td colSpan="2">Bio Counselors at law</td>
              <td>Direct TV</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">8</td>
              <td colSpan="4">Direct TV</td>
            </tr>
            <tr>
              <td></td>
              <td>
              <div className="ball"></div>
              </td>
              <td className=" text-right">7</td>
              <td>Morell, Cartagena, Dapena</td>
              <td>Mass Mutual</td>
              <td>Admin Office</td>
              <td className="bg-blues-light-blue">
                <b>AVAILABLE</b> 4.285 RSF
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">6</td>
              <td colSpan="4">Alight</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">5</td>
              <td colSpan="4" className="p-0">
                <div className="flex flex-row w-full">
                  <div className="w-1/3 py-3 ">Alight</div>
                  <div className="w-1/3 py-3 border-x-[1px]">SBA</div>
                  <div className="w-1/3 py-3 ">Alight</div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">4</td>
              <td colSpan="4">Alight</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className=" text-right">3</td>
              <td colSpan="4" className="p-0 border-0">
                <div className="w-[calc(100%_-_90px)] mx-[45px] py-3 border-x-[1px] text-center">
                  Alight
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="text-right pr-[29px]">
                MEZZ
              </td>
              <td colSpan="4" className="p-0 border-0">
                <div className="w-[calc(100%_-_90px)] mx-[45px] py-3 border-x-[1px] border-t-[1px] text-center">
                  Alight
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className="ball"></div>
              </td>
              <td className=" text-right">G</td>
              <td colSpan="4" className="p-0 border-0 border-b-[1px]">
                <div className="w-[calc(100%_-_90px)] mx-[45px] bg-blues-light-blue py-3 border-x-[1px] border-t-[1px]">
                  <b>AVAILABLE</b> 13.304 RSF
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="text-right pr-[29px]">
                <p className="">PARKING LEVEL 2</p>
              </td>
              <td
                colSpan="4"
                className="border-0 border-b-[1px] border-dashed py-[11px] px-0"
              >
                <div className="flex w-full flex-row justify-end">
                  <img src={car} className="mr-[26px]" />
                  <img src={car} />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="text-right pr-[29px]">
                <p>PARKING LEVEL 1</p>
              </td>
              <td
                colSpan="4"
                className="py-[11px] px-0 border-0 border-b-[1px] border-solid border-b-neutral-white"
              >
                <div className="flex w-full flex-row justify-end">
                  <img src={car} className="mr-[26px]" />
                  <img src={car} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Container1440Responsive>
    </div>
  );
};

export default TableDesktop;
