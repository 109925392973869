import React from "react";

const CertifiedIcon = ({ width, height }) => {
  return (
    <svg
      width={width ?? "65"}
      height={height ?? "55"}
      viewBox="0 0 65 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.792198 0.792677L64.2063 0.792677V53.2123L0.792198 53.2123V0.792677Z"
        stroke="#C9D1E4"
        strokeWidth="1.58535"
        strokeMiterlimit="10"
      />
      <path
        d="M11.8895 11.9113L53.1087 11.9113V42.0938H11.8895V11.9113Z"
        stroke="#017BF1"
        strokeWidth="1.58535"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default CertifiedIcon;
