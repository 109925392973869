import React, { useEffect, useRef, useState } from "react";
import { useIsBrowser } from "../../hooks";

const ScrollIndicator = ({ children, left = 0, className = '', targetClassName = '', targetStyles = {} ,noScrollPaddingTop }) => {
  const containerRef = useRef();
  const isBrowser = useIsBrowser();
  const [top, setTop] = useState(0);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
      handleScroll();
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }
  }, [isBrowser])

  const handleScroll = () => {
    const element = containerRef.current;
    const viewportHeight = window.innerHeight;

    const rect = element.getBoundingClientRect();
    const percentage = ((-(rect.top - viewportHeight) - 120) / rect.height) * 100;
    const maxTop = (rect.height - 55) / rect.height * 100;
    const distance = Math.min(maxTop, Math.max(0, percentage));

    setTop(distance);
  }

  return (
    <div ref={containerRef} className={`scroll-indicator-container`}>
      { children }

      <div className="scroll-indicator-shadow">
        <div className={`scroll-indicator-bar ${targetClassName}`} style={targetStyles}>
          <div className={`scroll-indicator ${className} ${noScrollPaddingTop ? 'h-full' : ''}`}  style={{ left, '--percent': `${top}%` }}></div>
        </div>
      </div>
    </div>
  );
}

export default ScrollIndicator;
