import React from "react";
import Reveal from "react-reveal/Reveal";
import Fade from "react-reveal/Fade";
import { StaticImage } from "gatsby-plugin-image";
import { Container1440Responsive } from "../common/global.styled";

const Workspaces = () => {
  return (
    <div className="relative">
      <div className="w-full bg-neutral-white">
        <Container1440Responsive className="pt-[78px]">
          <Fade bottom delay={500}>
            <p className="typo-h0 text-blues-light-blue pt-28 pb-20 break-words">
              MEET OUR WORKSPACES
            </p>
          </Fade>
        </Container1440Responsive>
      </div>
      <div className="w-full bg-blues-dark-blue">
        <Container1440Responsive className="">
          <div className="relative">
            <div className="w-full relative grid h-[276px] -top-[52px]">
              <StaticImage
                style={{
                  gridArea: "1/1",
                  height: "100%",
                }}
                layout="fullWidth"
                alt="Plaza 273"
                src={"../../images/header-spaces.jpg"}
                formats={["auto", "webp", "avif"]}
                imgClassName="object-top md:object-right"
              />
              <Reveal effect="fadeOutDown"  duration={1900} delay={300}>
              <div className="over-image"></div>
            </Reveal>
            </div>
          </div>

          <Fade bottom delay={300}>
            <p className="typo-body text-neutral-light-grey pt-0 pb-20 pt-3">
              Our building's construction dates back to 1985 and offers extensive
              space for all your specific business needs. Our team of space
              planners and engineers is also available to help you finetune the
              site maps and layouts that are necessary to make your office as
              comfortable and user-friendly as you need it to be.
            </p>
          </Fade>
        </Container1440Responsive>
      </div>
    </div>
  );
};

export default Workspaces;
