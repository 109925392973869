import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Reveal from "react-reveal/Reveal";
import Fade from "react-reveal/Fade";
import { Container1440 } from "../common/global.styled";
import WhyGrid from "./WhyGrid";

const WhyDesktop = () => {
  return (
    <div className="w-full border-b-[1px] border-b-background">
      <Container1440 className="flex flex-row">
        <div className="w-[43%] pb-[30px] pt-10 md:pl-[50px]  lg:pl-[60px] xl:pl-[100px] pr-[45px]">
          <div className="w-full md:max-w-[475px]">
            <Fade bottom>
              <p className="typo-h3 text-blues-light-blue">
                WHY <br />
                PLAZA 273?
              </p>
            </Fade>
          </div>
          <div className=" mt-8 md:pr-[5px]  lg:pr-[15px] xl:pr-[55px]">
            <Fade bottom delay={300}>
              <p className="typo-body text-neutral-grey">
                Because we know that every business has its own needs and
                requisites, we work with a team of visionary space planners and
                engineers who design spaces with collaboration and productivity in
                mind. Plaza 273 can provide turn key office spaces ideal for Act
                20 and 22 investors.
              </p>
              <br />
              <p className="typo-body text-neutral-grey">
                Equipped with state-of-the art amenities, you can count on safety
                and comfort without having to forgo functionality and style.
              </p>
            </Fade>
          </div>
        </div>
        <div className="w-[57%] pt-10 pb-[30px]  pr-[34px] pl-[30px]">
          <WhyGrid />
          <div className="mt-8  w-full">
            <div className="grid relative h-[410px] w-full">
              <StaticImage
                style={{
                  gridArea: "1/1",
                  width: "100%",
                  height: "100%",
                }}
                layout="constrained"
                alt="Plaza 273"
                src={"../../images/why.jpg"}
                height={500}
                formats={["auto", "webp", "avif"]}
                imgStyle={{ objectPosition: "center" }}
              />
              <Reveal effect="fadeOutDown"  duration={1900} delay={300}>
                <div className="over-image"></div>
              </Reveal>
            </div>
          </div>
        </div>
      </Container1440>
    </div>
  );
};

export default WhyDesktop;
