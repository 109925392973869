import React from "react";
import HomelandIcon from "../common/icons/HomelandIcon";
import CertifiedIcon from "../common/icons/CertifiedIcon";
import ParkingIcon from "../common/icons/ParkingIcon";
import PlannerIcon from "../common/icons/PlannerIcon";
import HighlighitItem from "../home/HighlighitItem";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const data = [
  {
    header: "Location",
    body: "Our building is in the very heart of Puerto Rico’s prestigious Golden Mile",
  },
  {
    header: "Sustainability",
    body: "Our building is certified as Energy Star, saving energy and costs",
  },
  {
    header: "parking",
    body: "Extensive, safe parking is available for your team and your visitors",
  },
  {
    header: "Customizable spaces",
    body: "We work with planners and engineers to be the best fit for your needs",
  },
];

const WhyGrid = () => {
  const breakpoints = useBreakpoint();
  const iconDimension = {
    width: breakpoints.sm ? "41" : "65",
    height: breakpoints.sm ? "34" : "54",
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[100px] gap-y-12 md:gap-y-20">
      <HighlighitItem {...data[0]}>
        <HomelandIcon {...iconDimension} />
      </HighlighitItem>
      <HighlighitItem {...data[1]}>
        <CertifiedIcon {...iconDimension} />
      </HighlighitItem>
      <HighlighitItem {...data[2]}>
        <ParkingIcon {...iconDimension} />
      </HighlighitItem>
      <HighlighitItem {...data[3]}>
        <PlannerIcon {...iconDimension} />
      </HighlighitItem>
    </div>
  );
};

export default WhyGrid;
