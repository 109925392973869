import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Reveal from "react-reveal/Reveal";
import WhyGrid from "./WhyGrid";
import PlannerIcon from "../common/icons/PlannerIcon";

const Why = () => {
  return (
    <div className="w-full border-b-[1px] border-b-background pb-14">
      <div className="grid grid-cols-1">
        <div className="gradient-blue-white px-4">
          <div className="grid relative h-[343px] ">
            <StaticImage
              style={{
                gridArea: "1/1",
              }}
              layout="constrained"
              alt="Why plaza?"
              src={"../../images/why-mobile.png"}
              formats={["auto", "webp", "avif"]}
              imgClassName=""
            />
            <Reveal effect="fadeOutDown"  duration={1900} delay={300}>
              <div className="over-image"></div>
            </Reveal>
          </div>
        </div>
        <div className="pt-14  px-4">
          <div className="flex w-full justify-end">
            <PlannerIcon width={"77"} height={"66"}/>
          </div>
          <p className="typo-h3 text-blues-light-blue pt-8">WHY PLAZA 273?</p>
          <div className=" mt-6">
            <p className="typo-body text-neutral-grey">
              Because we know that every business has its own needs and
              requisites, we work with a team of visionary space planners and
              engineers who design spaces with collaboration and productivity in
              mind. Plaza 273 can provide turn key office spaces ideal for Act
              20 and 22 investors.
            </p>
            <br />
            <p className="typo-body text-neutral-grey">
              Equipped with state-of-the art amenities, you can count on safety
              and comfort without having to forgo functionality and style.
            </p>
          </div>
          <div className="pt-14">
            <WhyGrid />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
