import React from "react";
import car from "../../images/car.png";
import { Container1440Responsive } from "../common/global.styled";

const Table = () => {
  return (
    <div className="bg-blues-dark-blue text-neutral-white custom-table-mobile">
      <Container1440Responsive className="pt-[27px] pb-[33px]">
        <table
          className="w-full text-left typo-table"
          cellPadding="0"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th colSpan={2} className="text-center pt-10 pb-9">
                PLAZA 273
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>16</td>
              <td>Mechanical</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Direct TV</td>
            </tr>
            <tr>
              <td className="border-b-0">14</td>
              <td className="border-b-0">Mass Mutual</td>
            </tr>
            <tr>
              <td className="border-t-0"></td>
              <td className="border-t-0 bg-blues-light-blue">
                <b>AVAILABLE</b> 2.263 RSF
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td className=" bg-blues-light-blue">
                <b>AVAILABLE</b> 15.521 RSF
              </td>
            </tr>
            <tr>
              <td className="border-b-0">12</td>
              <td className="border-b-0">Home Land Security</td>
            </tr>
            <tr>
              <td className="border-t-0"></td>
              <td className="border-t-0">Direct TV</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Home Land Security</td>
            </tr>
            <tr>
              <td>10</td>
              <td className=" bg-blues-light-blue">
                <b>AVAILABLE</b> 15.541 RSF
              </td>
            </tr>
            <tr>
              <td className="border-b-0">9</td>
              <td className="border-b-0 bg-blues-light-blue">
                <b>AVAILABLE</b> 2.647 RSF
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0"></td>
              <td className="border-b-0 border-t-0">Bio Counselors at law</td>
            </tr>
            <tr>
              <td className="border-t-0"></td>
              <td className="border-t-0">Direct TV</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Direct TV</td>
            </tr>
            <tr>
              <td className="border-b-0">7</td>
              <td className="border-b-0">Morell, Cartagena, Dapena</td>
            </tr>
            <tr>
              <td className="border-t-0 border-b-0"></td>
              <td className="border-t-0 border-b-0">Mass Mutual</td>
            </tr>
            <tr>
              <td className="border-t-0 border-b-0"></td>
              <td className="border-t-0 border-b-0 ">Admin Office</td>
            </tr>
            <tr>
              <td className="border-t-0"></td>
              <td className="border-t-0 bg-blues-light-blue"><b>AVAILABLE</b> 4.285 RSF</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Alight</td>
            </tr>
            <tr>
              <td className="border-b-0">5</td>
              <td className="border-b-0">Alight</td>
            </tr>
            <tr>
              <td className="border-t-0 border-b-0"></td>
              <td className="border-t-0 border-b-0">SBA</td>
            </tr>
            <tr>
              <td className="border-t-0"></td>
              <td className="border-t-0">Alight</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Alight</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Alight</td>
            </tr>
            <tr>
              <td>MEZZ</td>
              <td>Alight</td>
            </tr>
            <tr>
              <td>G</td>
              <td className=" bg-blues-light-blue">
                <b>AVAILABLE</b> 13.304 RSF
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="border-0 border-b-[1px] border-dashed">
                <div className="flex w-full flex-row justify-between">
                  <p>PARKING LEVEL 1</p>
                  <img src={car} className=" w-16 h-7 " />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="border-0 border-b-[1px]">
                <div className="flex w-full flex-row justify-between">
                  <p>PARKING LEVEL 2</p>
                  <img src={car} className=" w-16 h-7 " />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Container1440Responsive>
    </div>
  );
};

export default Table;
