import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Workspaces from "../components/spaces/Workspaces";
import WorkspacesDesktop from "../components/spaces/WorkspacesDesktop";
import Table from "../components/spaces/Table";
import TableDesktop from "../components/spaces/TableDesktop";
import Why from "../components/spaces/Why";
import WhyDesktop from "../components/spaces/WhyDesktop";
import ScrollIndicator from "../components/common/ScrollIndicator";

const Spaces = () => {
  const breakpoints = useBreakpoint();
  return (
    <Layout>
      <Seo title="Spaces" />
      {breakpoints.sm ? <Workspaces /> : <WorkspacesDesktop />}
      {breakpoints.sm ? <Table /> : <TableDesktop />}
      <ScrollIndicator left="43%" className="hidden md:block" noScrollPaddingTop={true}>
        {breakpoints.sm ? <Why /> : <WhyDesktop />}
      </ScrollIndicator>
    </Layout>
  );
};

export default Spaces;
