import React from "react";

const HomelandIcon = ({ width, height }) => {
  return (
    <svg
      width={width ?? "88"}
      height={height ?? "54"}
      viewBox="0 0 88 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.7101 53.0937L23.4374 53.0937L23.4374 0.791016L86.7101 0.791021L86.7101 53.0937Z"
        stroke="#C9D1E4"
        strokeWidth="1.58182"
        strokeMiterlimit="10"
      />
      <path
        d="M43.6975 41.9999L2.57021 41.9999L2.57021 11.8847L43.6975 11.8847L43.6975 41.9999Z"
        stroke="#017BF1"
        strokeWidth="1.58182"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default HomelandIcon;
