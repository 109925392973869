import React from "react";
import Reveal from "react-reveal/Reveal";
import Fade from "react-reveal/Fade";
import { StaticImage } from "gatsby-plugin-image";
import { Container1440Responsive } from "../common/global.styled";
import { Link } from "gatsby";
import BlueBigLogo from "../common/logo/BlueBigLogo";

const WorkspacesDesktop = () => {
  return (
    <div className="relative">
      <div className="w-full bg-neutral-white">
        <Container1440Responsive className="pt-10 grid grid-cols-2 gap-x-28">
          <div className="px-4 md:px-0">
            <Link to="/"><BlueBigLogo width="150" height="23"/></Link>
            <Fade bottom>
              <p className="typo-h0 text-blues-light-blue py-28 break-words">
                MEET OUR WORKSPACES
              </p>
            </Fade>
          </div>
        </Container1440Responsive>
      </div>
      <div className="w-full bg-blues-dark-blue">
        <Container1440Responsive className="pt-10 grid grid-cols-2 gap-x-28">
          <div>
            <Fade bottom delay={300}>
              <p className="typo-body text-neutral-light-grey">
                Our building's construction dates back to 1985 and offers
                extensive space for all your specific business needs. Our team of
                space planners and engineers is also available to help you
                finetune the site maps and layouts that are necessary to make your
                office as comfortable and user-friendly as you need it to be.
              </p>
            </Fade>
          </div>
        </Container1440Responsive>
      </div>
      <div className="w-full absolute top-0 h-full pointer-events-none">
      <Container1440Responsive className="pt-10 grid grid-cols-2 gap-x-28  h-full">
        <div></div>
        <div>
          <div className="grid relative h-full">
            <StaticImage
              style={{
                gridArea: "1/1",
                height: "100%",
              }}
              layout="fullWidth"
              alt="Plaza 273"
              src={"../../images/header-spaces.jpg"}
              formats={["auto", "webp", "avif"]}
              imgClassName="object-top md:object-right"
            />
            <Reveal effect="fadeOutDown"  duration={1900} delay={300}>
              <div className="over-image"></div>
            </Reveal>
          </div>
        </div>
      </Container1440Responsive></div>
    </div>
  );
};

export default WorkspacesDesktop;
