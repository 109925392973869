import React from "react";
import Fade from "react-reveal/Fade";

const HighlighitItem = ({ header, body, children }) => {
  return (
    <div className="w-full">
      <div className=" flex md:items-end w-full">
        {children}
      </div>
      <Fade bottom delay={500}>
        <p className="typo-h4 uppercase text-neutral-black mt-8">{header}</p>
      </Fade>
      <Fade bottom>
        <p className="typo-body text-neutral-grey mt-3">{body}</p>
      </Fade>
    </div>
  );
};

export default HighlighitItem;
